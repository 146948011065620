import { DashboardLayoutPasswordComponent } from "../dashboard/layout/dashboard-layout/components/password/password.component";
import { DashboardLayoutSesionComponent } from "../dashboard/layout/dashboard-layout/components/sesion/sesion.component";
import { CapacitacionesCreateClienteComponent } from "../dashboard/contents/capacitaciones/create/cliente/cliente.component";
import { CapacitacionesCreateParticipanteComponent } from "../dashboard/contents/capacitaciones/create/participante/participante.component";
import { CapacitacionesCreateContactoComponent } from "../dashboard/contents/capacitaciones/create/contacto/contacto.component";
import { CapacitacionesIndexCertificadosComponent } from "../dashboard/contents/capacitaciones/index/certificados/certificados.component";
import { CapacitacionesIndexEvaluacionComponent } from "../dashboard/contents/capacitaciones/index/evaluacion/evaluacion.component";
import { CapacitacionesIndexEvaluacionParticipanteComponent } from "../dashboard/contents/capacitaciones/index/evaluacion/participante/participante.component";
import { ClientesIndexParticipantesUploadFotoComponent } from "../dashboard/contents/clientes/index/participantes/upload-foto/upload-foto.component";
import { CapacitacionesTiposIndexEditComponent } from "../dashboard/contents/capacitacionesTipos/index/edit/edit.component";
import { InstructoresIndexEditComponent } from "../dashboard/contents/instructores/index/edit/edit.component";
import { InstructoresIndexUploadFirmaComponent } from "../dashboard/contents/instructores/index/upload-firma/upload-firma.component";
import { ClientesIndexContactosComponent } from "../dashboard/contents/clientes/index/contactos/contactos.component";
import { ClientesIndexContactosCreateComponent } from "../dashboard/contents/clientes/index/contactos/create/create.component";
import { ClientesIndexParticipantesComponent } from "../dashboard/contents/clientes/index/participantes/participantes.component";
import { ClientesIndexParticipantesCreateComponent } from "../dashboard/contents/clientes/index/participantes/create/create.component";
import { ClientesIndexEditComponent } from "../dashboard/contents/clientes/index/edit/edit.component";
import { UsuariosIndexEditComponent } from "../dashboard/contents/usuarios/index/edit/edit.component";
import { UsuariosIndexPasswordComponent } from "../dashboard/contents/usuarios/index/password/password.component";
import { UsuariosIndexPrivilegiosComponent } from "../dashboard/contents/usuarios/index/privilegios/privilegios.component";
import { CapacitacionesIndexFiltrosComponent } from "../dashboard/contents/capacitaciones/index/filtros/filtros.component";
import { CapacitacionesCertificadosFiltrosComponent } from "../dashboard/contents/capacitaciones/certificados/filtros/filtros.component";
import { CapacitacionesTiposIndexFiltrosComponent } from "../dashboard/contents/capacitacionesTipos/index/filtros/filtros.component";
import { ClientesIndexFiltrosComponent } from "../dashboard/contents/clientes/index/filtros/filtros.component";
import { InstructoresIndexFiltrosComponent } from "../dashboard/contents/instructores/index/filtros/filtros.component";
import { CapacitacionesIndexReporteComponent } from "../dashboard/contents/capacitaciones/index/reporte/reporte.component";
import { CapacitacionesIndexEmailComponent } from "../dashboard/contents/capacitaciones/index/email/email.component";
import { CapacitacionesIndexFiltroParticipanteComponent } from "../dashboard/contents/capacitaciones/index/filtro-participante/filtro-participante.component";
import { CapacitacionesCertificadosEditarComponent } from "../dashboard/contents/capacitaciones/certificados/editar/editar.component";
import { CapacitacionesIndexEditComponent } from "../dashboard/contents/capacitaciones/index/edit/edit.component";
import { CapacitacionesTiposIndexModulosComponent } from "../dashboard/contents/capacitacionesTipos/index/modulos/modulos.component";
import { CapacitacionesTiposIndexModulosCreateComponent } from "../dashboard/contents/capacitacionesTipos/index/modulos/create/create.component";
import { CapacitacionesTiposIndexModulosEditComponent } from "../dashboard/contents/capacitacionesTipos/index/modulos/edit/edit.component";
import { CapacitacionesTiposIndexModulosPreguntasComponent } from "../dashboard/contents/capacitacionesTipos/index/modulos/preguntas/preguntas.component";
import { OnlineCursosCreateComponent } from "../dashboard/contents/onlineCursos/create/create.component";
import { OnlineCursosIndexFiltrosComponent } from "../dashboard/contents/onlineCursos/index/filtros/filtros.component";
import { OnlineCursosCancelarComponent } from "../dashboard/contents/onlineCursos/cancelar/cancelar.component";
import { OnlineCursosEditComponent } from "../dashboard/contents/onlineCursos/edit/edit.component";
import { OnlineCursosUsuariosComponent } from "../dashboard/contents/onlineCursos/usuarios/usuarios.component";
import { CapacitacionesIndexPemexComponent } from "../dashboard/contents/capacitaciones/index/pemex/pemex.component";

export const ApplicationEntryComponents: any[] = [

    // C
    CapacitacionesCertificadosEditarComponent,
    CapacitacionesCertificadosFiltrosComponent,
    CapacitacionesCreateClienteComponent,
    CapacitacionesCreateContactoComponent,
    CapacitacionesCreateParticipanteComponent,
    CapacitacionesIndexCertificadosComponent,
    CapacitacionesIndexEditComponent,
    CapacitacionesIndexEmailComponent,
    CapacitacionesIndexEvaluacionComponent,
    CapacitacionesIndexEvaluacionParticipanteComponent,
    CapacitacionesIndexFiltroParticipanteComponent,
    CapacitacionesIndexFiltrosComponent,
    CapacitacionesIndexReporteComponent,
    CapacitacionesIndexPemexComponent,

    CapacitacionesTiposIndexEditComponent,
    CapacitacionesTiposIndexFiltrosComponent,
    CapacitacionesTiposIndexModulosComponent,
    CapacitacionesTiposIndexModulosCreateComponent,
    CapacitacionesTiposIndexModulosEditComponent,
    CapacitacionesTiposIndexModulosPreguntasComponent,

    ClientesIndexContactosComponent,
    ClientesIndexContactosCreateComponent,
    ClientesIndexEditComponent,
    ClientesIndexFiltrosComponent,
    ClientesIndexParticipantesComponent,
    ClientesIndexParticipantesCreateComponent,
    ClientesIndexParticipantesUploadFotoComponent,
    
    // D
    DashboardLayoutPasswordComponent,
    DashboardLayoutSesionComponent,

    // I
    InstructoresIndexEditComponent,
    InstructoresIndexFiltrosComponent,
    InstructoresIndexUploadFirmaComponent,

    // O
    OnlineCursosCancelarComponent,
    OnlineCursosCreateComponent,
    OnlineCursosEditComponent,
    OnlineCursosIndexFiltrosComponent,
    OnlineCursosUsuariosComponent,

    // U
    UsuariosIndexEditComponent,
    UsuariosIndexPasswordComponent,
    UsuariosIndexPrivilegiosComponent
]
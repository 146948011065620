import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSelect, MatSelectChange, MatBottomSheet } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Globals } from 'src/app/libraries/globals';
import { Wait } from 'src/app/ui/wait/Wait';
import { CapacitacionesService } from 'src/app/services/capacitaciones/capacitaciones.service';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common'; 
import { Sessions } from 'src/app/libraries/sessions';
import { ToastrService } from 'ngx-toastr';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { Router } from '@angular/router';
import { CapacitacionesIndexEvaluacionComponent } from './evaluacion/evaluacion.component';
import { CapacitacionesIndexCertificadosComponent } from './certificados/certificados.component';
import { CapacitacionesIndexFiltrosComponent } from './filtros/filtros.component';
import { CapacitacionesIndexReporteComponent } from './reporte/reporte.component';
import { CapacitacionesIndexEmailComponent } from './email/email.component';
import { CapacitacionesIndexFiltroParticipanteComponent } from './filtro-participante/filtro-participante.component';
import { CapacitacionesIndexEditComponent } from './edit/edit.component';
import { CapacitacionesIndexPemexComponent } from './pemex/pemex.component';

@Component({
  selector: 'comp-capacitaciones-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class CapacitacionesIndexComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false})    paginator       : MatPaginator;
  @ViewChild('tableContainer', {static: false}) tableContainer  : ElementRef;
  @ViewChildren('sltFilter')                    sltFilter       : QueryList<MatSelect>;

  private lstCapacitaciones   : Array<any> = [];
  private objDialogs          : Dialogs = new Dialogs();
  private index               : number;

  // SEARCH
  private txtSearch = new FormControl();

  // DATES FILTER
  private txtDateDesde  = new FormControl();
  private txtDateHasta  = new FormControl();

  // FILTERS
  private sltCapacitacionEstatus  = new FormControl();
  private sltCapacitacionGrupo    = new FormControl();
  private sltCapacitacionTipo     = new FormControl();
  private sltInstructor           = new FormControl();
  private sltCoordinador          = new FormControl();
  private sltAreaTematica         = new FormControl();
  private sltEstado               = new FormControl();
  private sltArea                 = new FormControl();
  private sltCreatedAspNetUser    = new FormControl();
  private sltUpdatedAspNetUser    = new FormControl();

  private lstCapacitacionesEstatus  : Array<any> = [];
  private lstCapacitacionesGrupos   : Array<any> = [];
  private lstCapacitacionesTipos    : Array<any> = [];
  private lstInstructores           : Array<any> = [];
  private lstCoordinadores          : Array<any> = [];
  private lstAreasTematicas         : Array<any> = [];
  private lstEstados                : Array<any> = [];
  private lstAreas                  : Array<any> = [{value: "Produccion", name: "Producción"},{value: "Perforacion", name: "Perforación"},{value: "Perforacion,Produccion", name: "Ambos"}];
  private lstCreatedAspNetUser      : Array<any> = [];
  private lstUpdatedAspNetUser      : Array<any> = [];

  // COLUMNS
  columns: string[] = ['index', 'folio', 'opciones', 'capacitacionEstatus', 'capacitacionGrupoName', 'capacitacionTipoName', 'duracion', 'instructorNombreCompleto', 'clienteRazonSocial', 'periodo', 'vigencia', 'createdAspNetUser', 'createdFecha', 'updatedAspNetUser', 'updatedFecha'];

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'Folio'}, 
                                {field : 'CapacitacionGrupoName'},
                                {field : 'CapacitacionTipoName'},
                                {field : 'InstructorNombreCompleto'},
                                {field : 'CoordinadorNombreCompleto'},
                                {field : 'ClienteRazonSocial'},
                                {field : 'ClienteRFC'},
                                {field : 'RepresentanteLegalNombreCompleto'},
                                {field : 'RepresentanteTrabajadorNombreCompleto'},
                                {field : 'OrdenCompra'},
                                {field : 'Ciudad'}];
  
  dataSource : MatTableDataSource<any>;

  private objPrivilegies : any;

  constructor(private objCapacitacionesService : CapacitacionesService,
              private objCapacitacionesTiposService : CapacitacionesTiposService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private objRouter : Router,
              private datePipe : DatePipe,
              private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.objPrivilegies = Sessions.getItem('privilegies');
    this.initialization();
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  btnFiltros_clickEvent() {
    
    var objData : any =
    {
      lstCapacitacionesEstatus  : this.lstCapacitacionesEstatus,
      lstCapacitacionesGrupos   : this.lstCapacitacionesGrupos,
      lstCapacitacionesTipos    : this.lstCapacitacionesTipos,
      lstInstructores           : this.lstInstructores,
      lstCoordinadores          : this.lstCoordinadores,
      lstAreasTematicas         : this.lstAreasTematicas,
      lstEstados                : this.lstEstados,
      lstAreas                  : this.lstAreas,
      lstCreatedAspNetUser      : this.lstCreatedAspNetUser,
      lstUpdatedAspNetUser      : this.lstUpdatedAspNetUser,

      // FORM CONTROL
      txtDateDesde            : this.txtDateDesde.value,
      txtDateHasta            : this.txtDateHasta.value,
      sltCapacitacionEstatus  : this.sltCapacitacionEstatus.value,
      sltCapacitacionGrupo    : this.sltCapacitacionGrupo.value,
      sltCapacitacionTipo     : this.sltCapacitacionTipo.value,
      sltInstructor           : this.sltInstructor.value,
      sltCoordinador          : this.sltCoordinador.value,
      sltAreaTematica         : this.sltAreaTematica.value,
      sltEstado               : this.sltEstado.value,
      sltArea                 : this.sltArea.value,
      sltCreatedAspNetUser    : this.sltCreatedAspNetUser.value,
      sltUpdatedAspNetUser    : this.sltUpdatedAspNetUser.value,
    }

    var bottomSheet = this._bottomSheet.open(CapacitacionesIndexFiltrosComponent, {
      data : objData
    });

    bottomSheet.instance.onChange.subscribe((item: CapacitacionesIndexFiltrosComponent) => {

      this.txtDateDesde.setValue(item.txtDateDesde.value);
      this.txtDateHasta.setValue(item.txtDateHasta.value);

      this.sltCapacitacionEstatus = item.sltCapacitacionEstatus;
      this.sltCapacitacionGrupo   = item.sltCapacitacionGrupo;
      this.sltCapacitacionTipo    = item.sltCapacitacionTipo;
      this.sltInstructor          = item.sltInstructor;
      this.sltCoordinador         = item.sltCoordinador;
      this.sltAreaTematica        = item.sltAreaTematica;
      this.sltEstado              = item.sltEstado;
      this.sltArea                = item.sltArea;
      this.sltCreatedAspNetUser   = item.sltCreatedAspNetUser;
      this.sltUpdatedAspNetUser   = item.sltUpdatedAspNetUser;

      if(Globals.validValue(item.sltCapacitacionGrupo.value)) {
        this.getCapacitacionesTipos(item.sltCapacitacionGrupo.value);
      }

      this.sltFilter.toArray()[0].value = this.sltCapacitacionEstatus.value;
      this.sltFilter.toArray()[1].value = this.sltCapacitacionGrupo.value;
      this.sltFilter.toArray()[2].value = this.sltCapacitacionTipo.value;
      this.sltFilter.toArray()[3].value = this.sltInstructor.value;
      this.sltFilter.toArray()[4].value = this.sltCoordinador.value;
      this.sltFilter.toArray()[5].value = this.sltAreaTematica.value;
      this.sltFilter.toArray()[6].value = this.sltEstado.value;
      this.sltFilter.toArray()[7].value = this.sltArea.value;
      this.sltFilter.toArray()[8].value = this.sltCreatedAspNetUser.value;
      this.sltFilter.toArray()[9].value = this.sltUpdatedAspNetUser.value;

      this.build();
      
    });
  }

  sltCapacitacionGrupo_changeEvent(event : MatSelectChange) {
    this.sltCapacitacionTipo.setValue("");
    this.lstCapacitacionesTipos = [];

    if(Globals.validValue(event.value)) {
      this.getCapacitacionesTipos(event.value);
    }
  }

  btnAsistencia_clickEvent(element : any) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/File/Asistencia/', element.idCapacitacion]);

    window.open(url.toString(), '_blank');
  }

  btnReporte_clickEvent(element : any) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/File/Reporte/', element.idCapacitacion]);

    window.open(url.toString(), '_blank');
  }

  btnEvaluacion_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(CapacitacionesIndexEvaluacionComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '1200px',
      maxHeight : '1200px'
    });

    dialogRef.componentInstance.objData = element;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnCertificados_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(CapacitacionesIndexCertificadosComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '1200px',
      maxHeight : '1200px'
    });

    dialogRef.componentInstance.objData = element;

  }

  btnDelete_clickEvent(element : any) {
    
    this.objDialogs.Confirm("Eliminar", "¿Eliminar capacitación con folio "+ element.folio +"?", "Eliminar", "Cancelar", ()=> {
      this.delete(element.idCapacitacion);
    });

  }

  btnReporteFilter_clickEvent() {
    const dialogRef = this.dialog.open(CapacitacionesIndexReporteComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '1200px',
      maxHeight : '1200px'
    });
  }

  btnFiltrarParticipante_clickEvent() {
    const dialogRef = this.dialog.open(CapacitacionesIndexFiltroParticipanteComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '1200px',
      maxHeight : '1200px'
    });
  }

  btnEmail_clickEvent(element : any) {
    const dialogRef = this.dialog.open(CapacitacionesIndexEmailComponent, {
      width         : '99%',
      height        : '99%',
      maxWidth      : '600px',
      maxHeight     : '700px',
      disableClose  : true
    });

    dialogRef.componentInstance.objCapacitacion = element;
  }

  btnEdit_clickEvent(element : any) {
    const dialogRef = this.dialog.open(CapacitacionesIndexEditComponent, {
      width         : '99%',
      height        : '99%',
      maxWidth      : '600px',
      maxHeight     : '350px',
      disableClose  : true
    });

    dialogRef.componentInstance.objData = element;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });
  }

  btnReportePemex_clickEvent() {
    const dialogRef = this.dialog.open(CapacitacionesIndexPemexComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '500px',
      maxHeight : '450px'
    });
  }

  // METODOS
  private initialization() : void {

    this.objCapacitacionesService.index()
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesEstatus = objResponse.result.capacitacionesEstatus;
          this.lstCapacitacionesGrupos  = objResponse.result.capacitacionesGrupos;
          this.lstInstructores          = objResponse.result.instructores;
          this.lstCoordinadores         = objResponse.result.instructores;
          this.lstAreasTematicas        = objResponse.result.areasTematicas;
          this.lstEstados               = objResponse.result.estados;
          this.lstCreatedAspNetUser     = objResponse.result.usuarios;
          this.lstUpdatedAspNetUser     = objResponse.result.usuarios;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
    }, error => {

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }
  
  private build() : void {

    let varArgs : any = {
      search              : Globals.validValue(this.txtSearch.value) ? this.txtSearch.value : "",
      searchFilters       : this.searchFilters,
      filters             : this.filters(),
      dateFrom            : Globals.validValue(this.txtDateDesde.value) ? this.datePipe.transform(this.txtDateDesde.value, 'yyyy-MM-dd') : "",
      dateTo              : Globals.validValue(this.txtDateHasta.value) ? this.datePipe.transform(this.txtDateHasta.value, 'yyyy-MM-dd') : "",
      dateOptions         : [{field : "CreatedFecha"}],
      columns             : [],
      persistenceColumns  : [],
      length              : !Globals.validValue(this.paginator.pageSize) ? 15 : this.paginator.pageSize,
      page                : this.paginator.pageIndex + 1
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objCapacitacionesService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitaciones    = objResponse.result.rows;

          this.dataSource           = new MatTableDataSource(this.lstCapacitaciones);
          this.paginator.pageIndex  = objResponse.result.page - 1;
          this.paginator.pageSize   = objResponse.result.length;
          this.paginator.length     = objResponse.result.count;
          this.index                = this.paginator.pageIndex * this.paginator.pageSize + 1;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private filters() {
    let lstFilters : Array<any> = [];

    if(Globals.validValue(this.sltFilter))
    {
      this.sltFilter.forEach(itemFilter => {

        if(Globals.validValue(itemFilter.value))
        {
          var filter : any = {
            field           : itemFilter.id,
            value           : itemFilter.value
          };

          lstFilters.push(filter);
        }

      })
    }

    return lstFilters;
  }

  private borrarFiltros() : void {
    this.txtSearch.setValue("");
    this.txtDateDesde.setValue("");
    this.txtDateHasta.setValue("");
    this.sltCapacitacionEstatus.setValue("");
    this.sltCapacitacionGrupo.setValue("");
    this.sltCapacitacionTipo.setValue("");
    this.sltInstructor.setValue("");
    this.sltCoordinador.setValue("");
    this.sltAreaTematica.setValue("");
    this.sltEstado.setValue("");
    this.sltArea.setValue("");
    this.sltCreatedAspNetUser.setValue("");
    this.sltUpdatedAspNetUser.setValue("");
    
    this.paginator.pageIndex  = 0;
    this.paginator.pageSize   = 15;
    this.lstCapacitacionesTipos = [];

    this.sltFilter.forEach(itemFilter => {
      itemFilter.value = "";
    });
    
    this.build();
  }

  private getCapacitacionesTipos(idCapacitacionGrupo : number) : void {
    
    this.sltCapacitacionGrupo.disable();
    this.sltCapacitacionTipo.disable();

    let objCapacitacionTipo : any =
    {
      idCapacitacionGrupo : idCapacitacionGrupo
    };

    this.objCapacitacionesTiposService.listByIdCapacitacionGrupo(objCapacitacionTipo)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesTipos = objResponse.result.capacitacionesTipos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.sltCapacitacionGrupo.enable();
      this.sltCapacitacionTipo.enable();

    }, error => {

      this.sltCapacitacionGrupo.enable();
      this.sltCapacitacionTipo.enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private delete(idCapacitacion : any) : void {
    
    Wait.show(this.tableContainer, "Eliminando...")

    let objCapacitacionDelete : any =
    {
      idCapacitacion : idCapacitacion
    };

    this.objCapacitacionesService.delete(objCapacitacionDelete)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);

    }, error => {

      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}

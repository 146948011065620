import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButton, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Globals } from 'src/app/libraries/globals';
import { CapacitacionesService } from 'src/app/services/capacitaciones/capacitaciones.service';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';
import { saveAs } from 'file-saver';

@Component({
  selector: 'comp-capacitaciones-index-pemex',
  templateUrl: './pemex.component.html',
  styleUrls: ['./pemex.component.css']
})
export class CapacitacionesIndexPemexComponent implements OnInit, AfterViewInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;

  private lstCapacitacionesTipos  : Array<any> = [];
  private lstInstructores : Array<any> = [];
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();

  // FILTERED CURSOS
  private idCapacitacionTipo          : number = 0;
  private txtCapacitacionTipo         = new FormControl();
  private filteredCapacitacionesTipos : Observable<string[]>;
  public meses : Array<any> = [{name: "Enero", id: 1},{name: "Febrero", id: 2},{name: "Marzo", id: 3},{name: "Abril", id: 4},{name: "Mayo", id: 5},{name: "Junio", id: 6},
                               {name: "Julio", id: 7},{name: "Agosto", id: 8},{name: "Septiembre", id: 9},{name: "Octubre", id: 10},{name: "Noviembre", id: 11},{name: "Diciembre", id: 12}]

  public anios : Array<number> = [];

  // DATOS TEMPORALES
  public idInstructor : number = 0;
  public area         : string = "Produccion";
  public periodo      : number = 0;
  public mes          : number = new Date().getMonth() + 1;
  public anio         : number = new Date().getFullYear();

  constructor(private objDialogRef : MatDialogRef<CapacitacionesIndexPemexComponent>, 
              private objCapacitacionesService : CapacitacionesService,
              private objCapacitacionesTiposService : CapacitacionesTiposService,
              private toastr: ToastrService) 
              { }

  ngOnInit() {
    const startYear = 2020;
    const currentYear = new Date().getFullYear();
    this.anios = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i).reverse();

    setTimeout(() => {
      this.initialization();
      this.initialization2();
      this.updatedFilterCapacitacionesTipos();
    });
  }

  ngAfterViewInit(): void {
    
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  // PREDICTIVE CAPACITACIONES TIPOS
  autoCapacitacionTipo_selectedEvent(event : any) {
    this.idCapacitacionTipo = event.option.id.idCapacitacionTipo;
  }

  txtCapacitacionTipo_clickEvent() {
    this.idCapacitacionTipo = 0;
    this.txtCapacitacionTipo.setValue("");

    this.updatedFilterCapacitacionesTipos();
  }

  focusOutCapacitacionTipo_function() {
    setTimeout(() => {
      if(this.idCapacitacionTipo == 0)
      {
        this.txtCapacitacionTipo.setValue("");
      }
    }, 200);
  }

  btnGuardar_clickEvent() {
    if(this.idCapacitacionTipo == 0) {
      this.toastr.warning('Seleccione el curso para descargar el reporte', 'Reporte', { timeOut: 2000 });
      return;
    }

    if(this.idInstructor == 0) {
      this.toastr.warning('Seleccione un instructor para descargar el reporte', 'Reporte', { timeOut: 2000 });
      return;
    }

    this.downloadReporte();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Espere por favor...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private updatedFilterCapacitacionesTipos() : void {
    this.filteredCapacitacionesTipos = this.txtCapacitacionTipo.valueChanges
    .pipe(
      startWith(''),
      map(value => this.filterCapacitacionesTipos(value))
    );
  }

  private filterCapacitacionesTipos(value: string): string[] {
    const filterValue = Globals.validValue(value) ? value.toLowerCase() : "";

    return this.lstCapacitacionesTipos.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private initialization() : void {

    this.loading(true);

    let objPost : any =
    {
      idCapacitacionGrupo : 1
    };

    this.objCapacitacionesTiposService.listByIdCapacitacionGrupo(objPost)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesTipos = objResponse.result.capacitacionesTipos;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);
      
    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private initialization2() : void {

    this.objCapacitacionesService.index()
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstInstructores  = objResponse.result.instructores;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
    }, error => {

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private downloadReporte() : void {

    this.loading(true);

    let objPost : any =
    {
      idCapacitacionTipo  : this.idCapacitacionTipo,
      idInstructor        : this.idInstructor,
      area                : this.area,
      periodo             : this.periodo,
      anio                : this.anio,
      mes                 : this.mes
    };

    this.objCapacitacionesService.fileReportePemex(objPost)
    .subscribe( objResponse => {

      saveAs(objResponse, this.periodo == 0 ? `Reporte PEMEX ${this.meses[this.mes - 1].name}.xlsx` : `Reporte PEMEX ${this.anio}.zip`);

      this.loading(false);

    }, error => {
      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}

